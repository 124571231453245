    * {
font-family: "Helvetica Neue", Helvetica;
font-size: 15px;
font-variant: normal;
padding: 0;
margin: 0;
}

form {
margin: 20px 0;
}

table td, table td * {
    vertical-align: top;
}

.button-container {
width: 100%;
text-align: center;
}

.button-style {
background:#5198b0;
color: white;
box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10),
0 3px 6px 0 rgba(0, 0, 0, 0.08);
border-radius: 4px;
border: 0;
margin: 20px auto;
font-size: 15px;
font-weight: 400;
width: 60%;
height: 40px;
outline: none;
cursor: pointer
}

.button-style:focus {
background: #155266;
}

.button-style:active {
background: #155266;
}

.button-style[disabled] {
background:  #155266;
}

.outcome {
float: left;
width: 100%;
padding-top: 8px;
min-height: 24px;
text-align: center;
}

.success,
.error,
.loader {
display: none;
font-size: 13px;
}

.success.visible,
.error.visible {
display: inline;
}

.loader.visible {
display: block;
}

.error {
color: #E4584C;
}

.success {
color: #666EE8;
}

.success .token {
font-weight: 500;
font-size: 13px;
}


.loader {
border: 4px solid #f3f3f3; /* Light grey */
border-top: 4px solid #666EE8; /* Blue */
border-radius: 50%;
width: 25px;
height: 25px;
animation: spin 2s linear infinite;
margin: 8px
}

.loader-small {
border: 4px solid #f3f3f3; /* Light grey */
border-top: 4px solid #666EE8; /* Blue */
border-radius: 50%;
width: 2em;
height: 2em;
animation: spin 1s linear infinite;
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

.input-box {
margin-left: 12px;
margin-right: 6px;
margin-top: 3px;
margin-bottom: 3px;
border-radius: 3px;
border: 1px solid #ccc;
}

.grid-container-1 {
display: grid;
grid-template-columns: 1fr;
grid-gap: 14px;
margin-top: 8px;
}

.grid-container-1-1 {
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 14px;
margin-top: 8px;
}

.grid-container-2-1 {
display: grid;
grid-template-columns: 60% 36%;
grid-gap: 14px;
margin-top: 8px;
}

.grid-container-1-1-1 {
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap: 14px;
margin-top: 8px;
}

.grid-container-name {
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 14px;
margin-top: 8px;
}

.grid-container-card {
display: grid;
grid-template-columns: 2fr 1fr;
grid-gap: 14px;
margin-top: 8px;
}

.grid-container-exp {
display: grid;
grid-template-columns: 1fr 1fr 2fr;
grid-gap: 14px;
margin-top: 8px;
}

.grid-container-label {
display: grid;
grid-template-columns: 2fr 1fr;
grid-gap: 14px;
margin-top: 0px;
}

.grid-container-city {
display: grid;
grid-template-columns: 2fr 1fr 1fr;
grid-gap: 14px;
margin-top: 10px;
}

.field-label {
position:relative;
top:6px;
left:10px;
padding: 0px 4px;
background-color: white;
width: fit-content;
font-size:11px;
color:#5198b0;
}

.field-label-drop {
position:relative;
top:14px;
left:10px;
padding: 0px 6px;
background-color: white;
width: fit-content;
font-size:11px;
color:#5198b0;
}

.field-input {
font-size:14px;
padding: 14px 10px 10px 18px;
width: 90%;
border: 1px solid #aaa;
border-radius: 10px;
color: #444;
}

.section-label {
width: 100%;
border-bottom: 1px solid #5198b0;
padding: 2px;
text-transform: uppercase;
color: #5198b0;
margin-top: 28px;
font-size: 11px;
font-weight: bold;
}

.payment-type-container {
width: 100%;
text-align: center;
}

.payment-type-button {
background:#5198b0;
color: white;
box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10),
0 3px 6px 0 rgba(0, 0, 0, 0.08);
border-radius: 4px;
border: 0;
margin: 10px;
font-size: 11px;
font-weight: 400;
width: 40%;
height: 34px;
outline: none;
cursor: pointer;
text-transform: uppercase;
}

#payment-response-section {
padding: 20px;
}
#payment-response {
font-size: 20px;
font-weight: bold;
color: #5198b0;
}

#payment-reference {
padding: 10px;
text-transform: uppercase;
font-size: 20px;
font-weight: bold;
color: #5198b0;
}

#processing-fee-message {
  padding-top: 20px;
  font-size: 12px;
}
