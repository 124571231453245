.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .SectionTitle {
    font-family: 'Work Sans';
    font-size: 1.1em;
    font-weight: 700;
    padding: 2px;
    border-bottom: 3px solid #053e8f;
    color: #053e8f;
  }
  
  .QuickLinksTitle {
    color: #22a845;
    font-size: 1.1em;
    font-weight: bold;
  }
  
  .QuickLinks {
    line-height: 175%;
    font-size: 1.1em;
    cursor: pointer;
  }
  
  .FooterCompanyName {
    font-size: 1em;
    font-weight: bold;
  }
  
  .FooterCompanyAddress {
    font-size: .9em;
  }
  
  a {
    color: white;
    text-decoration: none;
  }
  
  a.blue {
    color: #053e8f;
    text-decoration: none;
    text-decoration: none;
  }
  
  a:hover {
    color: #22a845;
    text-decoration: none;
  }
  
  .table-tall {
    line-height: 250%;
  }
  
  th {
    font-size: .9em;
    border-bottom: 1px solid #053e8f;
    color: #053e8f;
    text-align: left;
  }
  
  .Total {
    font-size: .9em;
    font-weight: bold;
    border-top: 1px solid #053e8f;
    color: #053e8f;
    text-align: left;
  }
  
  .Subtotal {
    border-top: 1px solid #053e8f;
  }
  
  .FormLabel {
    font-size: .7em;
    font-weight: 500;
    color: #053e8f;
    margin: 15px 0px 1px 6px;
    text-transform: uppercase;  
  }
  
  .FormInput {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #bbb;
  }

  .FormInputTextArea {
    width: 100%;
    padding: 10px;
    height: 100px;
    font-family: sans-serif;
  }

  .confirmationMessage {
    font-size: 1.2em;
    margin-top: 20px;
    min-height: 300px;
  }

  .Paragraph {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: .9em;
  }
  
  .form-tall {
    line-height: 200%;
  }
  
  .main-nav-link {
    display: 'inline-block';
    padding: 8px 20px;
    font-size: 0.9em;
    cursor: 'pointer';
    text-decoration: none;
  }
  
  .modal {
    z-index: 1; /* Sit on top */
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-top: 50px;
    width: 400px;
  }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    width: 100%; /* Could be more or less, depending on screen size */
    border-radius: 10px;
    border: solid 1px #fefefe;
    padding: 10px;
    min-height: 300px;
  }
  
  .login-title {
    width: 80%;
    margin: auto;
    padding: 20px 0px 6px 0px;
    border-bottom: 1px solid #888;
    text-transform: uppercase;
    font-size: 0.9em;
    color: #053e8f;
    font-weight: bold;
    text-align: center;
  }
  
  .login-form {
    width: 100%;
    margin: auto;
    padding: 20px 6px 6px 6px;
    font-size: 1em;
    color: #111;
    line-height: 150%;
  }
  
  .login-input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #888;
  }
  
  .login-message {
    padding: 10px;
  }
  
  .login-form-label {
    display: inline-block;
    width: 180px;
    text-align: right;
    padding-right: 10px;
  }

  .login-error {
      margin: 20px;
      border: 1px solid;
      border-radius: 3px;
      padding: 10px;
      color: red;
      background-color: #fff0f0;
      font-size: 0.9em;
  }

  .hidden {
      display: none;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap');
  